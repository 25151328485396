'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.assets.controller:AssetManagementCtrl

 # @description

###
class AssetManagementCtrl
  ### @ngInject ###
  constructor:(
    $state
    $scope
    AssetManager
  ) ->
    @currentTab = $state.current.data.selectedTab

    $scope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) =>
      @currentTab = toState.data.selectedTab

angular
  .module('mundoAdmin.assets')
  .controller 'AssetManagementCtrl', AssetManagementCtrl
